import React, { useState, useEffect } from 'react'
import '../../style/doctors.css'
import { format } from 'date-fns'
import { DateRange } from 'react-date-range'
import axios from 'axios'
import { toast } from 'react-toastify'
import DocCard from '../../components/docCard/DocCard'
import PageNav from '../../components/homeNav/PageNav'
import Footer from "../../components/homeNav/footer/Footer"

export default function Doctors() {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState('')
  const [specialty, setSpecialty] = useState('')

  const [doctors, setDoctors] = useState([])

  const fetchDoctors = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/doctors?name=${name}&specialty=${specialty}&limit=0`,
      )

      const { data } = result

      console.log("result",result.data)

      data.sort((a, b) => {
        const dateA = new Date(a.date).getTime()
        const dateB = new Date(b.date).getTime()

        if (dateA < Date.now() && dateB < Date.now()) {
          return dateA - dateB
        }
        if (dateA < Date.now()) {
          return 1
        }
        if (dateB < Date.now()) {
          return -1
        }
        return dateA - dateB
      })

      //setDoctors(data.data)
      setDoctors(data)
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: Doctors.jsx:35 ~ fetchDoctors ~ err:', err)
      setLoading(false)
      const { message } = err.response.data
      toast.error(message)
    }
  }

  useEffect(() => {
    fetchDoctors()
  }, [name,specialty])

  return (
    <div>
      {/* <TopNav /> */}
      <PageNav />
      {/* <Header type="list" /> */}
      <div className="listContainer">
        <div className="listWrapper">
          <div className="listSearch">
            <h1 className="lsTitle">Search</h1>
            <div className="lsItem">
              <label>Provider's Name</label>
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                style={{ borderRadius: '5px' }}
              />
            </div>
            <div className="lsItem">
              <label>Provider's Speciality</label>
              <input
                type="text"
                value={specialty}
                onChange={e => setSpecialty(e.target.value)}
                style={{ borderRadius: '5px' }}
              />
           </div>
            {/* <div className="lsItem">
              <label>Dates Available</label>
              <span onClick={() => setOpenDate(!openDate)}>{`${format(
                date[0].startDate,
                "MM/dd/yyyy"
              )} to ${format(date[0].endDate, "MM/dd/yyyy")}`}</span>
              {openDate && (
                <DateRange
                  onChange={item => setDate([item.selection])}
                  minDate={new Date()}
                  ranges={date}
                />
              )}
            </div> */}

            {/* <button  style={{ borderRadius: '10px' }} type="button" onClick={() => fetchDoctors()}>
              Search
            </button> */}
          </div>
          <div className="listResult">
            {doctors.map(doctor => (
              <DocCard doctor={doctor} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
